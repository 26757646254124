<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Codes
      <router-link slot="right" :to="{ name: 'code.new' }" class="button">
        New Code
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <code-table :checkable="true"/>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CodeTable from '@/components/SSIPLENGG/CodeTable.vue'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
export default {
  name: 'Codes',
  components: {
    HeroBar,
    TitleBar,
    CodeTable
  },
  computed: {
    titleStack () {
      return ['Admin', 'Codes']
    },
    ...mapState('code', ['total'])
  }
}
</script>
